var axios = require("axios").default;
const baseUrl = `https://api.ebsr.in/users`;
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localData.token
export default class System {
    async SystemList() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}/user_list`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };

    async ProfileList(data) {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}/fetch/${data}`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };

    async DistrictList() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}/district_by_state/29`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };

    async DesginationList() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}/role_list`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };

    async HospitalList() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}/view/viewHCRPHospital`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };

    async updateSystem(data) {
        try {
            const response = await axios.post(`${baseUrl}/update_other_users`, data, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })

            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }

    };
}