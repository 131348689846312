<template>
  <v-card flat class="mt-2">
    <!-- <v-card-text class="d-flex"> -->
    <!-- <v-avatar rounded size="120" class="me-6">
        <v-img :src="`https://api.ebsr.in/${profiledata.profile_picture}`"></v-img>
      </v-avatar> -->
    <!-- <div>
        <v-btn
          color="primary"
          class="me-3 mt-5"
          @click="$refs.refInputEl.click()"
          v-model="ProfilePicture"
        >
          <v-icon class="d-sm-none">
            {{ icons.mdiCloudUploadOutline }}
          </v-icon>
          <span class="d-none d-sm-block">Upload Profile Picture</span>
        </v-btn>
        <input
          ref="refInputEl"
          type="file"
          accept="image/*"
          :hidden="true"
          @change="onProfilePicture"
        />
      </div> -->
    <!-- </v-card-text> -->
    <v-card-text>
      <v-form class="multi-col-validation mt-3">
        <v-row class="mx-3">
          <v-col cols="12" md="2">
            <p>Profile Image</p>
            <img v-img style="width:100%" :src="`https://api.ebsr.in/${(profiledata.profile_picture) ? profiledata.profile_picture : 'uploads/sign/72ca2fd8-210a-4cb0-978a-d2cd5fd7e0e6certificate.png'}`" />
          </v-col>
          <v-col cols="12" md="3">
            <v-file-input v-model="ProfilePicture" chips accept="image/*" label="Add/Edit Profile Picture"
              @change="onProfilePicture" />
          </v-col>
          <v-col md="1" cols="12"></v-col>
          <v-col cols="12" md="2">
            <p>Signature Image</p>
            <img v-img style="width:100%" :src="`https://api.ebsr.in/${(profiledata.signature) ? profiledata.signature : 'uploads/sign/72ca2fd8-210a-4cb0-978a-d2cd5fd7e0e6certificate.png'}`" />
          </v-col>
          <v-col cols="12" md="3">
            <v-file-input v-model="Signature" chips accept="image/*" label="Add/Edit Signature Image"
              @change="onFileChange" />
          </v-col>
          <v-col md="1" cols="12"></v-col>
          <v-col md="5" cols="12">
            <v-text-field v-model="profiledata.name" label="Name"></v-text-field>
          </v-col>
          <v-col md="1" cols="12"></v-col>
          <v-col cols="12" md="5">
            <v-text-field v-model="profiledata.email" label="E-mail" readonly></v-text-field>
          </v-col>
          <v-col md="1" cols="12"></v-col>
          <v-col md="5" cols="12">
            <v-text-field v-model="profiledata.phone_number" label="Phone Number" @keypress="onlyNumber"></v-text-field>
          </v-col>
          <v-col md="1" cols="12"></v-col>
          <v-col cols="12" md="5">
            <v-autocomplete v-model="profiledata.district" label="District" :items="districtList"
              item-text="district_title"></v-autocomplete>
          </v-col>
          <v-col md="1" cols="12"></v-col>
          <v-col cols="12" md="5">
            <v-autocomplete v-model="profiledata.hospital_id" label="Hospital Name" :items="centerList"
              item-text="hospital_name" item-value="id"></v-autocomplete>
          </v-col>
          <v-col md="1" cols="12"></v-col>
          <v-col cols="12" md="5">
            <v-autocomplete v-model="profiledata.designation" label="Designation" :items="roleList" item-text="role"
              :disabled="permissionCheck"></v-autocomplete>
          </v-col>
          <v-col md="1" cols="12"></v-col>
        </v-row>
        <v-row class="mx-3">
          <v-col cols="12" md="11">
            <v-btn color="primary" class="float-right mb-6 mt-4" @click="profileEdit()"> Save changes </v-btn>
          </v-col>
          <v-col md="1" cols="12"></v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import Editsystem from '@/service/System.service'
import { ref } from '@vue/composition-api'
import axios from 'axios'
const baseUrl = 'https://api.ebsr.in/'
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localData.token
const userId = localData.user_id;



export default {
  data() {
    return {
      profiledata: [],
      image: null,
      Signature: null,
      ProfilePicture: null,
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
      roleList: [{ role: '' }],
      permissionCheck: true,
      centerList: [{ id: "", hospital_name: "" }],
      districtList: [{ district_title: '' }],
    }
  },

  props: {
    accountData: {
      type: Object,
      default: () => { },
    },
  },

  setup(props) {
    const accountDataLocale = ref(JSON.parse(JSON.stringify(props.accountData)))
    const resetForm = () => {
      accountDataLocale.value = JSON.parse(JSON.stringify(props.accountData))
    }

    return {
      accountDataLocale,
      resetForm,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        Editsystem,
      },
    }
  },

  watch: {
    options: {
      handler() {
        this.GetDistrictList()
        this.GetDesginationList()
        this.GetHospitalList()
        this.UserList()
      },
      deep: true,
    },
  },

  async mounted() {
    this.GetDistrictList()
    this.GetDesginationList()
    this.GetHospitalList()
    this.UserList()
    this.permissionCheckDesignation()
  },

  methods: {

    permissionCheckDesignation() {
      const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));;
      if (localData == "Admin" || localData == "Manager") {
        this.permissionCheck = false
      }
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length > 9) {
        $event.preventDefault()
      }
    },

    async GetDistrictList() {
      const service = new Editsystem()
      let response = await service.DistrictList()
      if (response) {
        this.districtList = response.data
      } else {
        this.districtList = []
      }
    },

    async GetDesginationList() {
      const service = new Editsystem()
      let response = await service.DesginationList()
      if (response) {
        this.roleList = response.data
      } else {
        this.roleList = []
      }
    },

    async GetHospitalList() {
      const service = new Editsystem()
      let response = await service.HospitalList()

      if (response) {
        this.centerList = response.data
      } else {
        this.centerList = []
      }
    },

    onFileChange() {
      const reader = new FileReader()
      reader.readAsDataURL(this.Signature)
      reader.onload = e => {
        // 2 mb = 2097152

        // if(e.total < 2097152){
        //   this.snackbar = true;
        //   this.snackbarText = "Please upload file below 2 MB";
        //   this.Signature = null;
        //   return;
        // }
      
        this.profiledata.signature = e.target.result
      }
    },

    onProfilePicture() {
      const reader = new FileReader()
      reader.readAsDataURL(this.ProfilePicture)
      reader.onload = e => {

        // if(e.total < 5242880){
        //   this.snackbar = true;
        //   this.snackbarText = "Please upload file below 5 MB";
        //   this.ProfilePicture = null;
        //   return;
        // }

        this.profiledata.profile_picture = e.target.result
      }
    },

    async UserList() {
      const service = new Editsystem()
      let response = await service.ProfileList(userId)
      this.profiledata = response
      // this.GetHospitalList()
    },

    async profileEdit() {
      if (this.profiledata.phone_number.length < 10) {
        return false,
          this.snackbarText = "Phone number should be 10 character.",
          this.snackbar = true
      }
      const data = {
        id: userId,
        name: this.profiledata.name,
        phone_number: this.profiledata.phone_number,
        district: this.profiledata.district,
        hospital_id: this.profiledata.hospital_id,
        designation: this.profiledata.designation,
        signature: this.profiledata.signature,
        profile_picture: this.profiledata.profile_picture
      }
      try {
        const response = await axios.post(`${baseUrl}users/update_user_profile`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          },
        })
        if (response.data.status == 200) {
          this.snackbarText = response.data.message
          this.UserList()
        } else {
          this.snackbarText = response.data.message
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
